<template>
<div class="reset owf-h" v-if="!send">
    <div class="reset-title">
        <h1 >Reset Passoword enter Email</h1>
        <p >Вітаємо на нашому ресурсі! Проведіть реєстрацію:</p>
    </div>
    <div class="flex justify-center">
        <div class="login-card">
             
             <el-input placeholder="Електронна пошта*"  v-model="input"></el-input>
            <div class="flex login-button">
                <button class="button" @click="send=true" >Продовжити</button>
            </div> 
        </div>
    </div>
    <div class="login-bottom-text ">
        <p >Політика конфіденційності</p>
    </div>
</div>
<div class="reset" v-else>
    <div class="reset-title">
        <h1>Enter code</h1>
        <p>Вітаємо на нашому ресурсі! Проведіть реєстрацію:</p>
    </div>
    <div class="flex justify-center">
        <div class="login-card">
            <div class="login-card-text">
                <p >Ми відправили SMS на номер  +380 97 *** ** 30 введіть 4-х значний код</p>
            </div>
             <el-input  placeholder="Код*" v-model="input"></el-input>
            <div class="flex login-button">
                <button class="button" @click="$router.push('/reset')" >Продовжити</button>
            </div> 
        </div>
    </div>
    <div class="login-bottom-text ">
        <p >Політика конфіденційності</p>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            input: '',
            send:false,
            password_show1: false,
            password_show2: false,
        }
    },
}
</script> 

<style lang="scss">
.reset {
    padding: 200px 120px 100px 120px;
    background: var(--white-bg);
}

.reset-title {
    margin-bottom: 40px;

    h1 {
        font-family: Stem-Medium;
        font-size: 51px;
        line-height: 60px;
        text-align: center;

        color: var(--other-black);
    }

    p {
        font-family: Stem-Medium;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        margin-top: 20px;

        color: var(--white-black);
    }
}

.login-card {
    width: 100%;
    max-width: 600px;
    padding: 60px;
    box-shadow: 0px 6px 16px rgba(0, 72, 153, 0.12), 0px 9px 28px rgba(0, 72, 153, 0.05), 0px 12px 48px rgba(0, 72, 153, 0.03);
    background: var(--white-bg);
    border-radius: 10px;
    margin-top: 60px;

    .el-input__inner {
        border: none;
        border-bottom: 1.5px solid #DDDFE2;
        padding: 20px;
        height: auto;
        font-family: Stem-Regular;
        font-size: 1.125rem;
        line-height: 1.25rem;
        display: flex;
        align-items: center;
        color: var(--white-black);
        border-radius: 0;

    }

    .black-check {
        margin-top: 20px;
        display: flex;
        align-items: center;

        p {
            font-family: Stem-Regular;
            font-size: 1.125rem;
            line-height: 1.25rem;
            margin-left: 10px;
            color: var(--white-black);
        }
    }

    .login-button {
        margin-top: 60px;
        display: flex;
        justify-content: center;
    }

   

     
}
.login-card-text{
    padding-bottom: 20px;
    p{
        font-family: Stem-Regular;
        font-size: 1.125rem;
        line-height: 1.25rem; 
        color: var(--other-black);
    }
}
.login-bottom-text {

    padding: 40px 20px 0px 20px;

    p {
        font-family: Stem-Regular;
        font-size: 1rem;
        line-height: 1.125rem;
        color: var(--white-black);
        text-align: center;
    }
}

@media screen and(max-width:1130px) {

    .reset {
        padding: 200px 20px 40px 20px;
    }

}

@media screen and(max-width:768px) {

    .reset {
        padding: 150px 0px 40px 0px;
        background: var(--bg-top);
    }

    .login-card {
        max-width: 470px;
        margin-top: 40px;
        padding: 40px 20px;

        .el-input__inner {
            padding: 18px;
            font-size: 1rem;
            line-height: 1.125rem;

        }

      
    }
    .login-card-text{
    p{
        font-size: 1rem;
        line-height: 1.125rem;
        color: var(--other-black);
        text-align: center;
    }
}
    .reset-title {
        padding: 0px 20px;
        margin-bottom: 40px;

        h1 {
            font-size: 24px;
            line-height: 28px;
        }

        p {
            font-size: 0.875rem;
            line-height: 1rem;
            margin-top: 10px;
        }
    }

    .login-bottom-text {

        padding: 40px 20px 0px 20px;

        p {
            font-family: Stem-Regular;
            font-size: 1rem;
            line-height: 1.125rem;
            color: var(--white-black);
            text-align: center;
        }
    }

}
</style>
